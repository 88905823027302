import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Container from "../components/layouts/container/Container"
import ColumnsThree from "../components/layouts/container/ColumnsThree"
import Layout from "./../components/layouts/Default"
import SectionTitle from "../components/commons/SectionTitle"
import H2 from "../components/typography/H2"
import P from "../components/typography/P"
import SvgWebiste from "../components/commons/svgs/SvgWebsite"
import parse from "html-react-parser"

export default function page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Gäste bei Die Summe aller Dinge"
      desc="Wer war bei uns im Podcast zu Gast? Hier findest du alle Interview-Partner."
    >
      <Container className="my-8 mmd:my-16">
        <ColumnsThree>
          <div className="col-span-1 mb-6 mmd:mb-0">
            <SectionTitle title="Gäste" className="mb-3" />
            <P>
              Wer war bei uns im Podcast zu Gast? Hier findest du alle
              Interview-Partner.
            </P>
          </div>
          <div className="grid grid-cols-1 col-span-2 gap-8">
            {data.allCockpitGaeste.nodes.map((guest, i) => {
              return (
                <div className="grid grid-cols-1 gap-3 mmd:grid-cols-3" key={i}>
                  {/* Image */}
                  <div>
                    {guest.website ? (
                      <a
                        href={guest.website.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          fluid={guest.foto.value.childImageSharp.fluid}
                          className="duration-300 transform shadow-xl rounded-xl hover:scale-110 "
                        />
                      </a>
                    ) : (
                      <Img
                        fluid={guest.foto.value.childImageSharp.fluid}
                        className="shadow-xl rounded-xl"
                      />
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div>
                        <H2 className="mb-5">{guest.name.value}</H2>
                        <P>
                          Zuhören in den Folgen:{" "}
                          <div className="inline-flex gap-1 ml-3">
                            {guest.folgen.value.map(folge => (
                              <Link
                                to={`/folgen/${folge.value}`}
                                className="inline-flex items-center justify-center w-8 h-6 text-white duration-300 transform bg-black rounded-lg hover:to-black700 hover:scale-110"
                              >
                                {folge.value}
                              </Link>
                            ))}
                          </div>
                        </P>
                      </div>
                      <div className="mb-3 prose">
                        {parse(guest.text.value)}
                      </div>
                      {guest.website && (
                        <div>
                          <a
                            href={guest.website.value}
                            className="text-gray-400 hover:text-gray-500"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="sr-only">Zur Website von {guest.name.value}</span>
                            <SvgWebiste className="w-6 h-6" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </ColumnsThree>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCockpitGaeste {
      nodes {
        name {
          value
        }
        text {
          value
        }
        website {
          value
        }
        folgen {
          value {
            value
          }
        }
        foto {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
